import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function Financial({ title, text }) {
  const [posterImage, setPosterImage] = useState("/video-financeiro.webp");

  useEffect(() => {
    const supportsWebP = () => {
      const canvas = document.createElement("canvas");
      return (
        canvas.getContext && canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0
      );
    };

    if (!supportsWebP()) {
      setPosterImage("/video-financeiro.png");
    }
  }, []);

  return (
    <MainSection
      reversed={false}
      id="financial"
      title={title}
      text={text}
      image={<Image alt={title} />}
      videoYoutube="https://www.youtube.com/embed/cHFJnOUXeDA?si=sfmDH47QTb48yio4"
      video={{
        src: '/video-financeiro.mp4',
        type: 'video/mp4',
        poster: posterImage
      }}
      items={[
        'Realize lançamentos financeiros com agilidade',
        'Acompanhe seu fluxo de caixa e gere relatórios',
        'Cadastre e gerencie suas contas e cartões facilmente',
      ]}
    />
  );
}

Financial.defaultProps = {
  title: "Controle Financeiro",
  text: `Centralize e simplifique sua gestão financeira com um controle completo em um único lugar. Organize suas receitas, despesas, contas e cartões com facilidade, e tenha uma visão clara do seu fluxo de caixa para tomar decisões mais seguras e estratégicas.`,
};

Financial.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Financial;
